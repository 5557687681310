<template>
  <v-card outlined color="pink lighten-5">
    <v-card-text>
      <v-layout row wrap align-center justify-space-between fill-height>
        <v-flex xs12 sm3>

          <v-autocomplete v-model="selectedProduct" hide-no-data ref="productSearch" outlined
            prepend-inner-icon="search" rounded dense :search-input.sync="phoneSearch" :loading="loading"
            auto-select-first single-line hide-details clearable id="productSearch" :items="Products" item-text="name"
            return-object :label="$t('labels.searchProduct')">
            <template slot="item" slot-scope="{ item }">
              <span>{{ item.name }}</span>
              <v-spacer></v-spacer>
              <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber
                  ? 'light-blue darken-1'
                  : 'teal darken-1'
                " dark>
                <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                  <v-icon small>mdi-barcode-scan</v-icon>
                </v-avatar>
                <v-avatar left v-else class="teal darken-3">
                  <v-icon small>mdi-package-variant-closed</v-icon>
                </v-avatar>
                <span>{{ item.balance }}</span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex xs12 sm3>
          <v-text-field @focus="$event.target.select()" label="Item Name" @keyup.enter="$refs.qty.focus"
            :disabled="lockProductName" v-model="name" outlined dense hide-details ref="itemName"></v-text-field>
        </v-flex>
        <v-flex xs12 sm1>
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="qty" outlined dense hide-details
            label="Quantity" @keyup.enter="$refs.boxPrice.focus" v-model="qty"></v-text-field>
        </v-flex>
        <v-flex xs12 sm2>
          <v-text-field class="right-input" ref="boxPrice" @focus="$event.target.select()" label="Price"
            v-model="price" outlined @keyup.enter="submit" dense hide-details></v-text-field>
        </v-flex>
        <v-flex xs12 sm2>
          <v-text-field disabled class="right-input" ref="extAmount" outlined dense hide-details
            @focus="$event.target.select()" v-model="total" label="Ext Amount"></v-text-field>
        </v-flex>
        <v-flex d-flex xs12 sm1>
          <v-btn color="info" ref="addbutton" large @click="submit">
            add
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import productService from "@/modules/Product/service";
import { mapGetters } from "vuex";
import _ from 'lodash';

export default {
  data() {
    return {
      loading: false,
      Products: [],
      phoneSearch: null,
      selectedProduct: null,
      name: null,
      disabledQty: false,
      qty: 1,
      price: 0,
      total: 0
    };
  },
  created() {
    this.getProduct();
    this.emptyEntryBar();

  },
  computed: {
    ...mapGetters("invoice", ["Invoice", "InvoiceDetails"]),
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    }
  },
  watch: {
    phoneSearch: _.debounce(function (val) {
      // console.log('test', val)
      if (val.length > 2) {
        this.getProduct();
      }

    }, 300),
    qty() {
      this.total = this.qty * this.price;
    },
    price(val) {
      this.total = val * this.qty;
    },
    selectedProduct(val) {
      if (val) {
        this.setProduct(val);
      }
    }
  },
  methods: {
    setProduct(product) {
      if (product) {
        this.price = product.price;
        this.total = product.price * this.qty;
        this.name = product.name;

        this.$nextTick(() => {
          this.$refs.itemName.focus();
        });
      }
    },
    emptyEntryBar() {
      this.name = null;
      this.qty = 1;
      this.total = 0;
      this.price = 0;
      this.selectedProduct = null;
      setTimeout(() => {
        this.$refs.productSearch.focus();
      }, 300);
    },
    submit() {
      if (!this.selectedProduct) return false;
      this.selectedProduct.Bar = {
        name: this.name,
        qty: this.qty,
        price: this.price
      };

      this.$emit("submit", this.selectedProduct);
      this.emptyEntryBar();
    },
    getProduct() {
      console.log('product', this.phoneSearch)
      this.loading = true;
      return productService.getAll({ search: this.phoneSearch, hideParent: true }).then(result => {
        this.Products = result.data;
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
