import backofficeService from '../../api/backoffice'
import crmapi from '../../api/crmapi'


export default {

  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  async getAll(params) {
    return backofficeService.Api().get('product', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },


  async fix(params) {
    return backofficeService.Api().get('product/fix', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },


  async minimumReached(params) {
    return backofficeService.Api().get('product/minimumReached', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  async upcSearch(params) {
    return backofficeService.Api().get('product/upcSearch', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  async getAllCaregoryProduct(params) {
    return backofficeService.Api().get('product', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`product/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  merge(data) {
    return backofficeService.Api().post('product/merge', data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },


  getAvgCost(id, params = {}) {
    return backofficeService.Api().get(`product/avgCost/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  updateBalance(id, params = {}) {
    return backofficeService.Api().get(`product/updateBalance/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getSyncInfo(id, params = {}) {
    return backofficeService.Api().get(`product/syncInfoById/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getPriceRange(params) {
    return backofficeService.Api().get('product/range', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getHistory(id, params = {}) {
    return backofficeService.Api().get(`product/history/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  async getGooglePic(params = {}) {
    try {
      let data = {
        username: process.env.VUE_APP_CRM_USERNAME,
        password: process.env.VUE_APP_CRM_PASSWORD
      }
      const token = await (await crmapi.Api().post('/apiToken', data)).data.token
      
      const response = await crmapi.Api(token).get(`cspApi/googleSearch`, { params })
      return response.data
    } catch (error) {
      // console.log('error', error)
    }
    
 
    
  },

  /**
   * Create a product. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('product', data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Update specific product
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`product/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`product/${id}`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  stockAdjustment : {
    create(data) {
      return backofficeService.Api().post('stockAdjustment', data)
        .then(response => {
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
  }

}