<template>
  <div>
    <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getVendor"
    ></Create>
    <v-autocomplete
      clearable
      :search-input.sync="vendorInput"
      outlined
      auto-select-first
      rounded
      :error-messages="errorMessage"
      prepend-inner-icon="search"
      dense
      :loading="loading"
      single-line
      hide-details
      :items="Vendors"
      label="Select Vendor"
      item-text="name"
      return-object
      v-model="selectedVendor"
    >
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item @click="createDialog = true">
          <v-list-item-content>
            <v-list-item-title>Create New Vendor</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
const Create = () => import("@/modules/Inventory/Vendor/components/Create");
import vendorService from "@/modules/Inventory/Vendor/service.js";

export default {
  props: ["errorMessage", "initValue"],
  data() {
    return {
      Vendors: [],
      loading: false,
      createDialog: false,
      vendorInput: null,
      selectedVendor: null,
    };
  },
  watch: {
    initValue(val) {
      this.selectedVendor = val;
    },
    selectedVendor(val) {
      if (val) {
        this.$emit("input", val);
        // this.selectedVendor = null;
        // this.vendorInput = null;
      }
    },
  },
  async created() {
    await this.getVendor();
    if (this.initValue) {
      this.selectedVendor = this.initValue;
    }
  },
  components: {
    Create,
  },
  methods: {
    getVendor() {
      this.loading = true;
      return vendorService.getAll().then((response) => {
        this.loading = false;
        this.Vendors = response.data;
        return response;
      });
    },
  },
};
</script>

<style scoped></style>
