<template>
  <v-container grid-list-lg fluid>
    <v-alert v-if="selectedVendor" border="left" type="error" transition="slide-y-transition" dismissible class="mb-1"
      v-model="selectedVendor.alert" color="red">
      <strong>{{ selectedVendor.note }}</strong>
    </v-alert>
    <v-layout column>
      <v-flex>
        <v-card outlined>
          <v-toolbar dense flat color="grey lighten-3">
            <v-toolbar-title> Create PurchaseOrder </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
            <v-btn color="info" small :disabled="PurchaseOrder.PurchaseOrderDetails.length === 0"
              @click="savePurchaseOrder">{{ invoiceTotal | currency }} Save</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="pl-2" justify="space-between">
              <v-col>
                <v-autocomplete :error-messages="selectVendorError" :items="Vendor" outlined auto-select-first rounded
                  prepend-inner-icon="search" dense label="Select Vendor" single-line
                  :hide-details="selectVendorError.length === 0" clearable item-text="name" return-object
                  v-model="selectedVendor">
                  <template slot="item" slot-scope="{ item }">
                    <span>{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <span>{{ item.balance | currency }}</span>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="createDialog = true">
                      <v-list-item-content>
                        <v-list-item-title>Create New Vendor</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <DateSelector label="Date" v-model="PurchaseOrder.date" />
              </v-col>
              <v-col class="shrink">
                <v-dialog v-model="orderNote" width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on">PurchaseOrder Note</v-btn>
                  </template>

                  <v-card>
                    <ModelTitle title="PurchaseOrder Note" @close="orderNote = !orderNote" />

                    <v-card-text class="mt-3">
                      <v-textarea outlined dense hide-details v-model="PurchaseOrder.note"
                        label="PurchaseOrder Note"></v-textarea>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="orderNote = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex>
        <v-card outlined>
          <v-card-text>
            <v-layout row wrap align-center justify-space-between fill-height>
              <v-flex xs12 sm3>
                <v-autocomplete v-model="selectedProduct" hide-no-data ref="productSearch" outlined auto-select-first
                  prepend-inner-icon="search" rounded dense single-line hide-details clearable id="productSearch"
                  :items="Products" item-text="name" return-object :label="$t('labels.searchProduct')">
                  <template slot="item" slot-scope="{ item }">
                    <span>{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber
                        ? 'light-blue darken-1'
                        : 'teal darken-1'
                      " dark>
                      <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                        <v-icon small>mdi-barcode-scan</v-icon>
                      </v-avatar>
                      <v-avatar left v-else class="teal darken-3">
                        <v-icon small>mdi-package-variant-closed</v-icon>
                      </v-avatar>
                      <span>{{ item.balance }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field @focus="$event.target.select()" label="Item Name" @keyup.enter="$refs.qty.focus"
                  :disabled="lockProductName" v-model="name" outlined dense hide-details ref="itemName"></v-text-field>
              </v-flex>
              <v-flex xs12 sm1>
                <v-text-field class="centered-input" @focus="$event.target.select()" ref="qty" outlined dense
                  hide-details label="Quantity" @keyup.enter="$refs.boxPrice.focus" v-model="qty"></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field class="right-input" ref="boxPrice" @focus="$event.target.select()" label="Price"
                  v-model="price" outlined dense hide-details @keyup.enter="$refs.extAmount.focus"></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field @keyup.enter="$refs.addbutton.$el.focus()" class="right-input" ref="extAmount" outlined
                  dense hide-details @focus="$event.target.select()" v-model="total" label="Ext Amount"></v-text-field>
              </v-flex>
              <v-flex d-flex xs12 sm1>
                <v-btn color="info" ref="addbutton" large @click="addRow">
                  add
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <v-card outlined>
          <v-card-text>
            <v-data-table :headers="headers" hide-default-footer no-data-text="No item on this invoice yet"
              :items="PurchaseOrder.PurchaseOrderDetails">
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-layout align-center justify-start row fill-height wrap>
                      <v-btn icon @click="deleteItem(index)">
                        <v-icon color="red">delete</v-icon>
                      </v-btn>
                      <v-text-field v-model="item.name" outlined hide-details @focus="$event.target.select()"
                        :disabled="lockProductName" dense></v-text-field>
                    </v-layout>
                  </td>
                  <td class="text-center">
                    <v-text-field v-model="item.qty" :disabled="item.imeiNumber ? true : false" outlined
                      class="centered-input" hide-details dense @focus="$event.target.select()"></v-text-field>
                  </td>
                  <td class="text-right">
                    <v-text-field v-model="item.price" outlined class="al-r" hide-details
                      @focus="$event.target.select()" dense></v-text-field>
                  </td>
                  <td class="text-right">
                    {{ (item.qty * item.price) | currency }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card v-if="totalQty > 0" outlined tile>
            <v-card-text>
              <div class="text-center">
                <span class="font-weight-medium">{{ totalQty }}</span> Total
                Item
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import customerService from "@/modules/Inventory/Vendor/service";
import productService from "@/modules/Product/service";
import orderService from "../service";
import DateSelector from "@/components/DateSelector";
import { mapGetters } from "vuex";

const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");

export default {
  name: "create-order",
  data() {
    return {
      orderNote: false,
      selectedProduct: null,
      selectedVendor: null,
      Vendor: [],
      Products: [],
      loading: true,
      PurchaseOrder: {
        date: this.$moment().toISOString(),
        note: null,
        VendorId: null,
        PurchaseOrderDetails: [],
      },
      name: null,
      qty: 1,
      price: 0,
      total: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          width: "100",
          sortable: true,
        },
        {
          text: "Price",
          align: "right",
          value: "price",
          sortable: true,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: true,
        },
      ],
    };
  },
  components: {
    DateSelector,
  },
  created() {
    this.emptyEntryBar();
    this.getVendorList();
    this.getProduct();
    this.loadData();
  },
  mixins: [validationMixin],
  validations: {
    PurchaseOrder: {
      VendorId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    },
    totalQty() {
      let total = 0;
      if (this.PurchaseOrder.PurchaseOrderDetails) {
        this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
          total += parseFloat(row.qty);
        });
      }

      return total;
    },
    selectVendorError() {
      const errors = [];
      if (!this.$v.PurchaseOrder.VendorId.$dirty) return errors;
      if (!this.$v.PurchaseOrder.VendorId.required)
        errors.push("Pleaes select customer");
      if (!this.$v.PurchaseOrder.VendorId.minValue)
        errors.push("Pleaes select customer");
      return errors;
    },
    invoiceTotal() {
      let total = 0;
      this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
        total += row.total;
      });
      return total;
    },
  },
  watch: {
    selectedVendor() {
      this.PurchaseOrder.VendorId = this.selectedVendor.id;
    },
    qty() {
      this.total = this.qty * this.price;
    },
    price(val) {
      this.total = val * this.qty;
    },
    total() {
      this.price = this.total / this.qty;
    },
    selectedProduct(val) {
      if (val) {
        this.setProduct(val);
      }
    },
  },
  methods: {
    loadData() {
      const { id } = this.$route.params;
      return orderService.getById(id).then((response) => {
        this.PurchaseOrder = response.data;
        this.selectedVendor = this.PurchaseOrder.Vendor;
        this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
          row.total = row.qty * row.price;
        });
        return response;
      });
    },
    savePurchaseOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else if (this.PurchaseOrder.PurchaseOrderDetails.length > 0) {
        return orderService
          .update(this.PurchaseOrder.id, this.PurchaseOrder)
          .then(() => {
            // console.log("result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "PurchaseOrder was Updated!",
              });

            this.$router.go(-1);
          });
      }
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.PurchaseOrder.PurchaseOrderDetails.splice(i, 1);
        }
      });
    },
    emptyEntryBar() {
      this.name = null;
      this.qty = 1;
      this.total = 0;
      this.price = 0;
      this.selectedProduct = null;
      setTimeout(() => {
        this.$refs.productSearch.focus();
      }, 300);
    },
    setProduct(product) {
      if (product) {
        this.price = product.price;
        this.total = product.price * this.qty;
        this.name = product.name;

        this.$nextTick(() => {
          this.$refs.itemName.focus();
        });
      }
    },
    addRow() {
      if (this.selectedProduct) {
        this.PurchaseOrder.PurchaseOrderDetails.push({
          ProductId: this.selectedProduct.id,
          name: this.name,
          price: this.price,
          total: this.price * this.qty,
          qty: this.qty,
        });
        this.emptyEntryBar();
      }
    },
    getProduct() {
      return productService.getAll().then((result) => {
        this.Products = result.data;
      });
    },
    getVendorList() {
      this.loading = true;
      return customerService.getAll().then((response) => {
        this.loading = false;
        this.Vendor = response.data;

        if (this.$route.query && this.$route.query.VendorId) {
          const _customerId = this.$route.query.VendorId;
          const result = this.Vendor.find(({ id }) => id == _customerId);
          this.selectedVendor = result;
        }
        return response;
      });
    },
  },
};
</script>

<style scoped></style>
