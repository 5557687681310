<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDateFormatted"
        clearable
        @click:clear="clearDate"
        outlined
        hide-details
        dense
        :label="label"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @change="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["initValue", "label", 'emptyDate'],
  data: () => ({
    menu: false,
    date: new Date().toISOString(),
  }),
  created() {
    if (this.initValue) {
      this.date = this.initValue;
    }
    if(this.emptyDate){
      this.date = ''
    }
  },
  watch: {
    initValue() {
      this.date = this.initValue;
    },
    date() {
      let newDate = this.$moment(this.date).toISOString();
      this.$emit("input", newDate);
    },
  },
  computed: {
    computedDateFormatted() {
      return this.date ? this.$moment(this.date).format("MM/DD/YYYY") : "";
    },
  },
  methods: {
    clearDate(){
      this.date = ""
    }
  }
};
</script>

<style scoped></style>
