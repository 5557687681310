<template>
   <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "purchase-order",
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"])
  },
};
</script>
