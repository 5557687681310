<template>
  <div>
    <List />
  </div>
</template>

<script>
import List from "../components/List";
export default {
  data() {
    return {
      active: null
    };
  },
  components: {
    List
  }
};
</script>

<style scoped></style>
