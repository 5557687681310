<template>
  <v-container grid-list-lg fluid>
    <v-alert
      v-if="selectedVendor"
      border="left"
      type="error"
      transition="slide-y-transition"
      dismissible
      class="mb-1"
      v-model="selectedVendor.alert"
      color="red"
    >
      <strong>{{ selectedVendor.note }}</strong>
    </v-alert>
    <v-layout column>
      <v-flex>
        <v-card outlined>
          <v-toolbar dense flat :color="$style.purchase.titlebar">
            <v-toolbar-title> Create Purchase Order </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
            <v-btn
              color="info"
              small
              :disabled="PurchaseOrder.PurchaseOrderDetails.length === 0"
              @click="savePurchaseOrder"
              >{{ invoiceTotal | currency }} Save</v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pl-2" justify="space-between">
              <v-col>
                <AutoComplete
                  v-model="selectedVendor"
                  :errorMessages="selectVendorError"
                />
              </v-col>
              <v-col cols="3">
                <DateSelector label="Date" v-model="PurchaseOrder.date" />
              </v-col>
              <v-col class="shrink">
                <v-dialog v-model="orderNote" width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on">Note</v-btn>
                  </template>

                  <v-card>
                    <ModelTitle
                      title="Purchase Order Note"
                      @close="orderNote = !orderNote"
                    />

                    <v-card-text class="mt-3">
                      <v-textarea
                        outlined
                        dense
                        hide-details
                        v-model="PurchaseOrder.note"
                        label="Purchase Order Note"
                      ></v-textarea>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="orderNote = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex>
        <ProductSearchBar @submit="addRow" />
      </v-flex>
      <v-flex>
        <v-card outlined>
          <v-card-text>
            <v-data-table
              :headers="headers"
              hide-default-footer
              no-data-text="No item on this order yet"
              :items="PurchaseOrder.PurchaseOrderDetails"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-layout align-center justify-start row fill-height wrap>
                      <v-btn icon @click="deleteItem(index)">
                        <v-icon color="red">delete</v-icon>
                      </v-btn>
                      <v-text-field
                        v-model="item.name"
                        :disabled="lockProductName"
                        outlined
                        hide-details
                        @focus="$event.target.select()"
                        dense
                      ></v-text-field>
                    </v-layout>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.qty"
                      :disabled="item.imeiNumber ? true : false"
                      outlined
                      class="centered-input"
                      hide-details
                      dense
                      @focus="$event.target.select()"
                    ></v-text-field>
                  </td>
                  <td class="text-right">
                    <v-text-field
                      v-model="item.price"
                      outlined
                      class="al-r"
                      hide-details
                      @focus="$event.target.select()"
                      dense
                    ></v-text-field>
                  </td>
                  <td class="text-right">
                    {{ (item.qty * item.price) | currency }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card v-if="totalQty > 0" outlined tile>
            <v-card-text>
              <div class="text-left">
                <span class="font-weight-medium">{{ totalQty }}</span> Total
                Item
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");
import DateSelector from "@/components/DateSelector";
import AutoComplete from "@/modules/Inventory/Vendor/components/AutoComplete";
import ProductSearchBar from "@/modules/Inventory/PurchaseOrder/components/ProductSearchBar";
import orderService from "../service";
import { mapGetters } from "vuex";

export default {
  name: "create-purchase-order",
  data() {
    return {
      orderNote: false,
      selectedVendor: null,
      Vendor: [],
      loading: true,
      PurchaseOrder: {
        date: this.$moment().toISOString(),
        note: null,
        VendorId: null,
        PurchaseOrderDetails: [],
      },
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          width: "100",
          sortable: true,
        },
        {
          text: "Price",
          align: "right",
          value: "price",
          sortable: true,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: true,
        },
      ],
    };
  },
  components: {
    DateSelector,
    AutoComplete,
    ProductSearchBar,
  },
  mixins: [validationMixin],
  validations: {
    PurchaseOrder: {
      VendorId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName(){
      if(!this.currentBusiness.metadata.hasOwnProperty('lockProductName')){
        return true
      }else if(this.currentBusiness.metadata.lockProductName){
        return true
      }
      return false
    },
    totalQty() {
      let total = 0;
      if (this.PurchaseOrder.PurchaseOrderDetails) {
        this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
          total += parseFloat(row.qty);
        });
      }

      return total;
    },
    selectVendorError() {
      const errors = [];
      if (!this.$v.PurchaseOrder.VendorId.$dirty) return errors;
      if (!this.$v.PurchaseOrder.VendorId.required)
        errors.push("Pleaes select vendor");
      if (!this.$v.PurchaseOrder.VendorId.minValue)
        errors.push("Pleaes select vendor");
      return errors;
    },
    invoiceTotal() {
      let total = 0;
      this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
        total += row.total;
      });
      return total;
    },
  },
  watch: {
    selectedVendor() {
      this.PurchaseOrder.VendorId = this.selectedVendor.id;
    },
  },
  methods: {
    savePurchaseOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.PurchaseOrder.PurchaseOrderDetails.length > 0) {
          return orderService.create(this.PurchaseOrder).then(() => {
            // console.log("result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Purchase Order was created!",
              });

            this.$router.go(-1);
          });
        }
      }
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.PurchaseOrder.PurchaseOrderDetails.splice(i, 1);
        }
      });
    },

    addRow(val) {
      // console.log("addrow", val);
      this.PurchaseOrder.PurchaseOrderDetails.push({
        ProductId: val.id,
        name: val.Bar.name,
        price: val.Bar.price,
        total: val.Bar.price * val.Bar.qty,
        qty: val.Bar.qty,
      });
    },
  },
};
</script>

<style scoped></style>
